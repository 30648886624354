/* eslint-disable @next/next/no-img-element */
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import locals, { Lang, defaultLang } from "../../i18n";

const Footer: React.FC = () => {
  const router = useRouter();
  const lang: Lang = (router.locale ?? defaultLang) as Lang;
  const locale = locals[lang];
  return (
    <footer
      id="footer"
      className="footer-section flex flex-col justify-between h-auto lg:h-[86vh] bg-gray bottom-0"
    >
      <div
        id="footer_content"
        className="flex flex-col max-w-[100rem] justify-between h-full mx-auto"
      >
        <div className="flex flex-col lg:flex-row w-full justify-center lg:px-10">
          <div className="text-center text-2xl lg:text-left lg:w-2/3 text-white lg:text-5xl 2xl:text-7xl p-16 lg:order-first order-last">
            {locale.CONTACT_MESSAGE}
          </div>
          <div className="flex lg:w-1/3 mt-10 lg:mt-0 justify-center">
            <Image
              src={
                "https://res.cloudinary.com/dh79wyca2/image/upload/s--Pzq7lxQs--/f_auto,q_auto/ahmed-embothy-campbus"
              }
              className="object-cover lg:w-80 2xl:h-96 lg:h-auto rounded-full lg:rounded-none"
              width={250}
              height={250}
              alt="Ahmed Amer"
              // loading="lazy"
              itemProp="image"
            />
          </div>
        </div>
        <div className="lg:px-24">
          <div className="flex text-white w-full lg:mb-6 mb-[20%] lg:justify-between justify-center">
            <div className="text-3xl lg:text-xl inline-block align-middle relative w-min">
              <div className="link">
                <Link itemProp="email" href="mailto:ahmed@tammura.com">
                  ahmed<span className="text-image-profile">@tammura.com</span>
                </Link>
              </div>
              <div className="text-lightGray hidden lg:inline">
                {locale.MILAN} - {locale.ITALY}
              </div>
            </div>
            <div className="hidden w-1/2 lg:w-2/3 text-xl lg:inline-flex justify-between align-middle">
              <Link
                itemProp="url"
                rel="me"
                href="https://www.linkedin.com/in/tammura/"
                target="blank"
                className="magnetic-btn will-change-transform relative inline-block align-middle text-center cursor-pointer"
              >
                <span className="relative link-underline p-2 m-3 inline-block align-middle text-white text-center border-lg ease-linear duration-150">
                  LinkedIn
                </span>
              </Link>
              <Link
                itemProp="url"
                rel="me"
                href="https://www.instagram.com/_tammura/"
                target="blank"
                className="magnetic-btn will-change-transform relative inline-block align-middle text-center cursor-pointer"
              >
                <span className="relative p-2 m-3 inline-block align-middle text-white text-center border-lg ease-linear duration-150">
                  Instagram
                </span>
              </Link>
              <Link
                itemProp="url"
                rel="me"
                href="https://github.com/Tammura/"
                target="blank"
                className="magnetic-btn will-change-transform relative inline-block align-middle text-center cursor-pointer"
              >
                <span className="relative p-2 m-3 inline-block align-middle text-white text-center border-lg ease-linear duration-150">
                  Github
                </span>
              </Link>
            </div>
          </div>
          <div className="w-full text-lightGray text-center pb-4 bottom-0">
            Copyright &copy; 2022 - {new Date().getFullYear()} Ahmed Amer
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
