import React from "react";
import type { NextPage } from "next";
import BaseLayout from "../components/BaseLayout";
import Publications from "../components/Publications";
import fsPromises from "fs/promises";
import path from "path";
import ProjectCard, { ProjectProps } from "../components/ProjectCard";
import { NextRouter } from "next/router";
import locals, { Lang, defaultLang } from "../i18n";
import Image from "next/image";

const Home: NextPage = ({ data, sections }: any) => {
  return (
    <BaseLayout sections={sections}>
      <main itemScope itemType="http://schema.org/Person">
        {/* Hello Section */}
        <section id="personal-details">
          <div className="flex w-full h-[90vh] justify-center items-center lg:h-[90vh] lg:my-11">
            <div
              itemScope
              itemType="https://schema.org/jobTitle"
              className="flex flex-row w-full justify-center items-center h-fit welcome-section md:w-2/3 "
            >
              <h1
                itemProp="name"
                className="absolute w-2/3 z-0 text-lightGray opacity-10 text-9xl sm:text-[18vw] font-black uppercase break-all lg:-mt-24 lg:text-[18vw] lg:leading-[10rem] lg:xl:leading-[15vw]"
              >
                {data.helloSection.profession}
              </h1>
              <div className="flex flex-col w-11/12 sm:w-2/3 text-5xl sm:text-[9vw] font-medium">
                <h6 className="h-1/3 px-10 lg:px-0">
                  {data.helloSection.hello}
                </h6>
                <div className="flex flex-row px-10 lg:px-0">
                  <h3 className="flex items-center w-1/2">
                    {data.helloSection.iam}
                  </h3>
                  <p className="flex justify-start items-center w-full text-sm pl-3 md:pl-10 break-normal italic lg:w-1/3 lg:text-base lg:-ml-10">
                    {data.helloSection.fullName} {data.helloSection.or}{" "}
                    {data.helloSection.simply}
                  </p>
                </div>
                <h2 className="text-image-profile text-left font-extrabold text-[6.5rem] w-fit md:text-[13vw] lg:leading-[13vw]">
                  {data.helloSection.firstName}
                </h2>
              </div>
            </div>
          </div>
        </section>
        {/* Projects Section */}
        <section id="projects">
          <div className="flex w-full h-full justify-center projects-section">
            <div className="projects-wrapper font-SpaceMono">
              <h3 className="text-white text-5xl lg:text-8xl">
                {data.projectsSection.longTitle}
              </h3>
              <div className="projects-cards-wrapper">
                {data.projectsSection.projectsList.map(
                  (project: ProjectProps, index: number) => {
                    return <ProjectCard key={index} project={project} />;
                  }
                )}
              </div>
            </div>
          </div>
        </section>

        {/* Competences Section */}
        <section id="skills" itemScope itemType="http://schema.org/ItemList">
          <div className="flex w-full h-full justify-center my-[10rem] md:my-[25rem] competence-section">
            <div className="flex flex-row w-5/6 md:w-3/4 overflow-x-auto justify-between text-black text-3xl font-medium">
              {data.competencesSection.competencesList.map(
                (competence: any, index: number) => {
                  return (
                    <div
                      key={index}
                      itemType="http://schema.org/ItemList"
                      className="flex flex-none p-6 first:pl-6 last:pr-6"
                    >
                      <div className="flex flex-col items-center justify-center gap-3">
                        <Image
                          src={competence.image.src}
                          title={competence.name}
                          className=""
                          width={200}
                          height={200}
                          alt={competence.image.alt}
                          loading="lazy"
                        />
                        <h3 className="text-2xl lg:text-3xl">
                          {competence.name}
                        </h3>
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </section>
        {/* Pubblications Section */}
        <Publications articles={data.pubblicationsSection.pubblicationsList} />
      </main>
    </BaseLayout>
  );
};

export default Home;

export async function getStaticProps({ locale }: NextRouter) {
  const lang = (locale ?? defaultLang) as Lang;
  const filePath = path.join(process.cwd(), "json", "homepage.json");
  const jsonData = await fsPromises.readFile(filePath, "utf-8");
  const data = JSON.parse(jsonData)[lang];
  const local = locals[lang];

  const sections: { [key: string]: string } = { footerSection: local.BYE };

  for (const sectionName in data) {
    if (data.hasOwnProperty(sectionName) && data[sectionName].title) {
      sections[sectionName] = data[sectionName].title;
    }
  }

  return {
    props: {
      data,
      sections,
    },
  };
}
